import * as rg4js from 'raygun4js';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, concat, Observable, of } from 'rxjs';
import { filter, finalize, map, mapTo, take, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { IBranch } from '../branches/branches.service';
import { BRANCH_STORAGE_KEY } from '../branches/branches.constants';
import jwt_decode from 'jwt-decode';

export const ROLE_ADMIN = 'eSMS Admins';

export interface IUser {
  roles: string[];
  username: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {

  private readonly AUTH_TOKEN = 'eSalon.authToken';
  private readonly BRANCH = 'eSalon.branch';
  private readonly REFRESH_TOKEN = 'eSalon.refreshToken';
  private readonly USER = 'eSalon.user';

  private readonly _userSubject$: BehaviorSubject<IUser | null> = new BehaviorSubject(null);

  public readonly user$ = this._userSubject$.asObservable();

  constructor(
    private _httpClient: HttpClient,
    private _logger: NGXLogger,
    private _router: Router,
  ) {
    this._bindUserSubject();
  }

  private _bindUserSubject(): void {
    this._userSubject$.subscribe(user => {
      if (!!user) {
        rg4js('setUser', {
          identifier: user.username,
          isAnonymous: false
        });
      }
      else {
        rg4js('setUser', {
          isAnonymous: true
        });
      }
    });
  }

  private _completeLogin(username: string, token: TokenResponse): void {
    this._storeTokenResponse(token);

    const decodedToken: any = jwt_decode(token.authToken);

    const user: IUser = {
      roles: decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] ?? [],
      username: username.toLowerCase()
    };

    localStorage.setItem(this.USER, JSON.stringify(user));
    this._userSubject$.next(user);
  }

  private _completeLogout(): void {
    this._logger.trace('AuthService | completeLogout');

    this._userSubject$.next(null);

    rg4js('endSession');
    localStorage.removeItem(this.AUTH_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem(this.USER);
    localStorage.removeItem(this.BRANCH);
  }

  private _getBranchFromStorage(): IBranch | null {
    const storedBranch = window.localStorage.getItem(BRANCH_STORAGE_KEY);
    if (!storedBranch) { return null; }
    return JSON.parse(storedBranch);
  }

  private _getUserFromStorage(): Observable<IUser> {
    const user = JSON.parse(localStorage.getItem(this.USER));
    return of (user);
  }

  private _storeTokenResponse(token: TokenResponse): void {
    this._logger.trace('AuthService | setTokenResponse', token);

    localStorage.setItem(this.AUTH_TOKEN, token.authToken);

    if (!!token.refreshToken) {
      localStorage.setItem(this.REFRESH_TOKEN, token.refreshToken);
    }
  }

  confirmAccount(userId: string, token: string, password: string, passwordConfirm: string): Observable<void> {
    return this._httpClient.post<void>('/api/auth/confirm', {
      userId,
      token,
      password,
      passwordConfirm
    });
  }

  forgotPassword(email: string): Observable<void> {
    return this._httpClient.post<void>('/api/auth/forgotpassword', { email });
  }

  getAuthToken(): string {
    return localStorage.getItem(this.AUTH_TOKEN);
  }

  getBranchToken(branch: IBranch): Observable<void> {
    this._logger.trace('AuthService | getBranchToken');

    return this._httpClient.post<TokenResponse>('/api/auth/branch', {
      subscriptionId: branch.subscriptionId,
      branchRef: branch.id
    }).pipe(
      tap(token => {
        this._storeTokenResponse(token);
      }),
      map(_ => {})
    );
  }

  getRefreshToken(): string {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  getUser(): Observable<IUser | null> {
    const user = concat(
      this._userSubject$.pipe(take(1), filter(u => !!u)),
      this._getUserFromStorage().pipe(filter(u => !!u), tap(u => this._userSubject$.next(u))),
      this._userSubject$.asObservable()
    );
    return user;
  }

  hasRole(role: string): boolean {
    return this._userSubject$.value?.roles?.includes(role) ?? false;
  }

  isAuthenticated(): Observable<boolean> {
    return this.getUser().pipe(map(u => !!u));
  }

  login(email: string, password: string, remember: boolean): Observable<boolean> {
    return this._httpClient.post<TokenResponse>('/api/auth/login', { email, password, remember })
      .pipe(
        tap(response => {
          this._completeLogin(email, response);
          const returnUrl = this._router.routerState.snapshot.root.queryParams.returnUrl;
          if (returnUrl) {
            this._router.navigateByUrl(returnUrl);
          } else {
            this._router.navigateByUrl('/');
          }
        }),
        mapTo(true)
      );
  }

  logout(): Observable<void> {
    this._logger.trace('AuthService | logout');

    return this._httpClient.post<void>('/api/auth/logout', { refreshToken: this.getRefreshToken()})
      .pipe(
        finalize(() => {
          this._logger.debug('AuthService | logout | finalize');
          this._completeLogout();
          this._router.navigateByUrl('/auth/login');
          return of(null);
        })
      );
  }

  refreshToken(): Observable<TokenResponse> {
    const branch = this._getBranchFromStorage();

    return this._httpClient.post<TokenResponse>('/api/auth/refresh', {
      refreshToken: this.getRefreshToken(),
      branchRef: branch?.id,
      subscriptionId: branch?.subscriptionId
    })
    .pipe(
      tap(tokens => {
        this._storeTokenResponse(tokens);
      })
    );
  }

  resetPassword(email: string, password: string, passwordConfirmation: string, token: string): Observable<void> {
    return this._httpClient.post<void>('/api/auth/resetpassword', {
      email,
      password,
      passwordConfirmation,
      token
    });
  }
}

interface TokenResponse {
  authToken: string;
  refreshToken: string;
}

import * as rg4js from 'raygun4js';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import packageJson from '../../package.json';

rg4js('apiKey', environment.raygun.apiKey);
rg4js('setVersion', packageJson.version);
rg4js('enableCrashReporting', environment.production);

@Injectable({ providedIn: 'root' })
export class RaygunErrorHandler implements ErrorHandler {

  constructor(
    private _logger: NGXLogger
  ) { }

  handleError(e: any): void {
    this._logger.error(e);

    if (environment.production) {
      rg4js('send', {
        error: e,
      });
    }
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

import { BranchesService, IBranch } from '../branches.service';

@Injectable({ providedIn: 'root'})
export class BranchListResolver  {

  constructor(
    private branchesService: BranchesService,
    private router: Router
  ) { }

  resolve(): Observable<IBranch[]> | Observable<never> {
    return this.branchesService.getBranches().pipe(
      take(1),
      mergeMap(branches => {
        if (branches) {
          return of(branches);
        } else {
          this.router.navigateByUrl('/errors/500');
          return EMPTY;
        }
      })
    );
  }

}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SmsChatComponent } from './chat/sms-chat.component';
import { ISms } from './sms.model';

@Injectable({ providedIn: 'root' })
export class SmsService {

  constructor(
    @Inject('BASE_URL') private _baseUrl: string,
    private _http: HttpClient,
    private _logger: NGXLogger,
    private _modalService: NgbModal
  ) { }

  private _mapSms(data: any): ISms {
    const sms: ISms = {} as ISms;
    Object.assign(sms, data);
    sms.createdAt = DateTime.fromISO(data.createdAt);
    return sms;
  }

  private _mapSmses(smses: ISms[]): ISms[] {
    return smses.map(s => this._mapSms(s));
  }

  public openChatForCustomer(customerRef: number): void {
    this._logger.debug(`openChatForCustomer|${customerRef}`);

    const modalInstance = this._modalService.open(SmsChatComponent);
    modalInstance.componentInstance.customerRef = customerRef;
  }

  public openChatForSms(smsRef: number): void {
    this._logger.debug(`openChatForSms|${smsRef}`);

    this.find(smsRef).subscribe(sms => {
      if (!!sms.customerRef && sms.customerRef > 0) {
        this.openChatForCustomer(sms.customerRef);
      }
    });
  }

  public find(smsRef: number): Observable<ISms> {
    return this._http.get<ISms>(`${this._baseUrl}api/sms/${smsRef}`)
      .pipe(map(sms => this._mapSms(sms)));
  }

  public getSmsForCustomer(customerRef: number): Observable<ISms[]> {
    return this._http.get<ISms[]>(`${this._baseUrl}api/sms/customer/${customerRef}`)
      .pipe(map(smses => this._mapSmses(smses)));
  }

  public sendSms(customerRef: number, message: string): Observable<ISms> {
    return this._http.post<ISms>(`${this._baseUrl}api/sms/customer/${customerRef}`, { message })
      .pipe(map(sms => this._mapSms(sms)));
  }
}

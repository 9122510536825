import * as rg4js from 'raygun4js';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, concat, Observable, of } from 'rxjs';
import { concatMap, filter, map, take, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { BRANCH_STORAGE_KEY } from './branches.constants';
import { IBranchSettings } from './models/branch-settings.model';

export interface IBranch {
  id: number;
  name: string;
  staffRef: number;
  subscriptionId: number;
  business: IBusiness;
}

export interface IBusiness {
  id: number;
  name: string;
}

@Injectable({ providedIn: 'root' })
export class BranchesService {

  private readonly _branch$ = new BehaviorSubject<IBranch | null>(null);
  private readonly _branchSettings$ = new BehaviorSubject<IBranchSettings | null>(null);
  private readonly _branches$ = new BehaviorSubject([]);

  public readonly branch$ = this._branch$.asObservable();
  public readonly branchSettings$ = this._branchSettings$.asObservable();
  public readonly branches$ = this._branches$.asObservable();

  constructor(
    @Inject('BASE_URL') private baseUrl: string,
    private _authService: AuthService,
    private _http: HttpClient,
    private _logger: NGXLogger
  ) {
    this._bindAuthService();
    this._bindBranch();
  }

  private _bindAuthService(): void {
    this._authService.user$.subscribe(user => {
      if (!user) {
        this._logger.trace('BranchesService | _bindAuthService | no user');
        this._branch$.next(null);
      }
    });
  }

  private _bindBranch(): void {
    this._branch$.subscribe(branch => {
      if (!!branch) {
        rg4js('withCustomData', { branchRef: branch.id, branchName: branch.name, subscriptionRef: branch.subscriptionId });
      }
      else
      {
        rg4js('withCustomData', {});
      }
    });
  }

  private _getBranchFromStorage(): Observable<IBranch | null> {
    this._logger.trace('BranchesService | getBranchFromStorage');

    const storedBranch = window.localStorage.getItem(BRANCH_STORAGE_KEY);
    if (!storedBranch) { return of(null); }
    return of(JSON.parse(storedBranch));
  }

  private _addBranchToStorage(branch: IBranch): void {
    window.localStorage.setItem(BRANCH_STORAGE_KEY, JSON.stringify(branch));
  }

  getCurrentBranch(): Observable<IBranch | null> {
    this._logger.trace('BranchesService | getCurrentBranch');

    return concat(
      this._branch$.pipe(
        take(1),
        filter(branch => !!branch),
        tap(() => { this._logger.trace('BranchesService | getCurrentBranch | local branch'); })
      ),
      this._getBranchFromStorage().pipe(
        filter(branch => !!branch),
        concatMap(branch => this.setBranch(branch)),
        tap(() => { this._logger.trace('BranchesService | getCurrentBranch | storage branch'); })
      ),
      this.getBranches().pipe(
        take(1),
        filter(branches => branches.length === 1),
        map(branches => branches[0]),
        filter(branch => !!branch),
        tap(branch => this._logger.trace('BranchService|First branch from list', branch)),
        concatMap(branch => this.setBranch(branch)),
        tap(() => { this._logger.trace('BranchesService | getCurrentBranch | only branch'); })
      ),
      this._branch$.asObservable()
    );
  }

  getBranches(): Observable<IBranch[]> {
    this._logger.trace('BranchesService | getBranches');

    return this._http.get<IBranch[]>(`${this.baseUrl}api/subscriptions/branches`)
      .pipe(tap(x => this._branches$.next(x)));
  }

  getBranchSettings(): Observable<IBranchSettings> {
    this._logger.trace('BranchesService | getBranchSettings');

    return this._http.get<IBranchSettings>(`${this.baseUrl}api/branches/settings`)
      .pipe(tap(x => this._branchSettings$.next(x)));
  }

  setBranch(branch: IBranch | null): Observable<IBranch | null> {
    this._logger.trace('BranchesService | setBranch');

    if (branch === this._branch$.value) {
      this._logger.trace('BranchesService | setBranch | no change');
      return of(null);
    }

    return this._authService.getBranchToken(branch)
      .pipe(
        tap(_ => {
          this._addBranchToStorage(branch);
          this._branch$.next(branch);
        }, error => {
          this._logger.error('setBranch', error);
        }),
        map(_ => branch)
      );
  }

}

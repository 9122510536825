import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { BranchesService } from 'src/app/branches/branches.service';
import { ICustomer } from 'src/app/customers/customer.model';
import { CustomersService } from 'src/app/customers/customers.service';
import { SignalRService } from 'src/app/signalr/signalr.service';
import { ISms } from '../sms.model';
import { SmsService } from '../sms.service';

declare var SimpleBar: any;

@Component({
  templateUrl: './sms-chat.component.html'
})
export class SmsChatComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

  constructor(
    private _branchService: BranchesService,
    private _customerService: CustomersService,
    private _logger: NGXLogger,
    private _signalRService: SignalRService,
    private _smsService: SmsService,
    public modal: NgbActiveModal
  ) { }

  private _branchRef: number;
  private _needToScroll = false;
  private _timeFormat: string;
  private _today = DateTime.now().startOf('day');

  private readonly _busy$ = new BehaviorSubject<boolean>(true);
  private readonly _customer$ = new BehaviorSubject<ICustomer | null>(null);
  private readonly _destroy$ = new Subject<void>();
  private readonly _sending$ = new BehaviorSubject<boolean>(false);
  private readonly _sms$ = new BehaviorSubject<ISms[]>([]);

  public error: any;
  public newMessage = '';

  public readonly busy$ = this._busy$.asObservable();
  public readonly customer$ = this._customer$.asObservable();
  public readonly sending$ = this._sending$.asObservable();
  public readonly sms$ = this._sms$.asObservable();

  @Input() customerRef: number;

  @ViewChild('sendForm') customerForm: NgForm;
  @ViewChild('newMessageInput') newMessageInput: ElementRef;

  private _appendSms(sms: ISms): void {
    const smses = this._sms$.getValue();
    smses.push(sms);

    this._needToScroll = true;

    this._sms$.next(smses);
  }

  private _bindSignalR(): void {
    this._signalRService.smsReceived$
      .pipe(takeUntil(this._destroy$))
      .subscribe(smsData => {
        if (smsData.branchRef !== this._branchRef) { return; }
        if (smsData.customerRef !== this.customerRef) { return; }

        this._smsService.find(smsData.smsRef)
          .subscribe(sms => {
            this._appendSms(sms);
          });
      });
  }

  private _getBranchSettings(): Observable<void> {
    this._branchService.branch$
      .pipe(take(1))
      .subscribe(branch => {
        this._branchRef = branch.id;
      });

    return this._branchService.branchSettings$
      .pipe(
        take(1),
        tap(settings => {
          this._timeFormat = settings.use24HourTime ? 'HH:mm' : 'hh:mm a';
        }),
        map(_ => null)
      );
  }

  private _getCustomer(): Observable<void> {
    return this._customerService.find(this.customerRef)
      .pipe(
        tap(customer => this._customer$.next(customer)),
        map(_ => null)
      );
  }

  private _getSms(): Observable<void> {
    return this._smsService.getSmsForCustomer(this.customerRef)
      .pipe(
        tap(smses => {
          this._sms$.next(smses);
        }, error => {
          this._sms$.next([]);
          this._logger.error('SmsChatComponent', error);
          this.error = 'Could not load SMS';
        }),
        map(_ => null)
      );
  }

  private _loadChat(): Observable<void> {
    return forkJoin([
      this._getCustomer(),
      this._getSms()
    ]).pipe(
      tap(_ => {
        this._needToScroll = true;
      }),
      map(_ => null)
    );
  }

  private _scrollList(): void {
    const sb = SimpleBar.instances.get(document.querySelector('#smsList'));
    const el = sb.getScrollElement();
    el.scrollTop = el.scrollHeight;
  }

  public ngOnInit(): void {
    this._bindSignalR();

    forkJoin([
      this._getBranchSettings(),
      this._loadChat()
    ]).subscribe(_ => {
      this._busy$.next(false);
    }, error => {
      this._logger.error('ngOnInit', error);
      this._busy$.next(false);
    });
  }

  public ngAfterViewInit(): void {
    this.newMessageInput.nativeElement.focus();
  }

  public ngAfterViewChecked(): void {
    if (this._needToScroll) {
      this._scrollList();
      this._needToScroll = false;
    }
    this.newMessageInput.nativeElement.focus();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
  }

  public formatDate(date: DateTime): string {
    return (date.startOf('day') < this._today)
      ? date.toFormat(`d MMM ${this._timeFormat}`)
      : date.toFormat(this._timeFormat);
  }

  public onSend(): void {
    this._sending$.next(true);
    this.error = null;

    this._smsService.sendSms(this.customerRef, this.newMessage)
      .subscribe(sms => {
        this._appendSms(sms);
        this.newMessage = '';
        this._sending$.next(false);
        this.newMessageInput.nativeElement.focus();
      }, error => {
        this.error = error;
        this._sending$.next(false);
      });
  }

}

<!-- MAIN NAV -->
<div class="navbar-custom topnav-navbar navbar-dark bg-primary">

  <div class="container-fluid">

    <!-- LOGO -->
    <div class="logo-topbar">
      <a class="logo-dark" [routerLink]='["/appointments"]'>
        <span class="logo-lg">
          <img src="/assets/images/esalon-icon-border.svg" alt="eSalon" height="40" class="me-1" />
          <img src="/assets/images/esalon-text-white.svg" alt="eSalon" height="32" />
        </span>

        <span class="logo-sm">
          <img src="/assets/images/esalon-icon-border.svg" alt="eSalon" height="32" />
        </span>
      </a>
    </div>

    <ul class="list-unstyled topbar-menu float-end mb-0">

      <!-- Branch -->
      <app-branch-menu></app-branch-menu>

      <!-- Notifications -->
      <app-notification-menu></app-notification-menu>

      <!-- Appointment Book -->
      <li class="notification-list" *ngIf="!!(branch$ | async)">
        <a class="nav-link" [routerLink]='["/appointments"]' routerLinkActive="active" title="Appointment Book">
          <i class="bi bi-calendar-event noti-icon"></i>
        </a>
      </li>

      <!-- User -->
      <li class="dropdown notification-list">
        <a class="nav-link dropdown-toggle arrow-none me-0" data-bs-toggle="dropdown" id="topbar-userdrop" href="#" role="button" aria-haspopup="true" aria-expanded="false">
          <i class="bi bi-person-fill noti-icon"></i>
          <span class="d-none d-md-inline-block align-middle">{{ (user$ | async)?.username }}</span>
        </a>

        <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown" aria-labelledby="topbar-userdrop">
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">Welcome!</h6>
          </div>

          <a [routerLink]='["/settings/profile"]' class="dropdown-item notify-item">
            <i class="bi bi-person-circle me-1"></i>
            <span>My Account</span>
          </a>

          <a [routerLink]='["/auth/logout"]' [state]='{ local: true }' class="dropdown-item notify-item">
            <i class="bi bi-box-arrow-right me-1"></i>
            <span>Logout</span>
          </a>
        </div>
      </li>

    </ul>

  </div>

</div>

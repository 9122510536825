<div class="modal-header">
  <h3 class="modal-title">SMS Chat</h3>

  <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('close')">
  </button>
</div>

<div class="modal-body pt-0">
  <app-busy [in]="busy$ | async"></app-busy>
  <app-api-error [error]="error"></app-api-error>

  <ul id="smsList" class="conversation-list" data-simplebar style="max-height: 500px;">
    <li class="clearfix" *ngFor="let sms of sms$ | async" [ngClass]="{'odd': sms.direction == 'Outbound'}">
      <div class="chat-avatar">
        <i class="bi bi-person-fill" style="font-size: 1.6rem;" *ngIf="sms.direction == 'Inbound'"></i>
        <img src="/assets/icons/icon-192.png" alt="eSalon" class="rounded" *ngIf="sms.direction == 'Outbound'">
        <i class="d-block">{{ formatDate(sms.createdAt) }}</i>
      </div>
      <div class="conversation-text">
        <div class="ctext-wrap">
          <i *ngIf="sms.direction == 'Inbound'">{{ (customer$ | async)?.fullName }}</i>
          <i *ngIf="sms.direction == 'Outbound'">eSalon</i>
          <p>{{ sms.message }}</p>
        </div>
      </div>
    </li>
  </ul>

  <div class="mt-2 bg-light p-3 rounded">
    <form #sendForm="ngForm" (ngSubmit)="onSend()">
      <div class="row">
          <div class="col mb-2 mb-sm-0">
            <input #newMessageInput
                name="newMessage"
                type="text"
                class="form-control border-0"
                placeholder="Enter your text"
                required
                [(ngModel)]="newMessage"
                [disabled]="(sending$ | async)">
            <div class="invalid-feedback">Please enter your messsage</div>
          </div>
          <div class="col-sm-auto">
            <button type="submit" class="btn btn-primary chat-send" title="Send" [disabled]="sendForm.invalid || (sending$ | async)">
              <i class="bi bi-send" *ngIf="!(sending$ | async)"></i>

              <div class="spinner-border spinner-border-sm text-light" role="status" *ngIf="(sending$ | async)">
                <span class="visually-hidden">Sending...</span>
              </div>
            </button>
          </div> <!-- end col -->
      </div> <!-- end row-->
    </form>
  </div>
</div>

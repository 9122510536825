import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Component({
  template: ''
})
export class LogoutComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.authService.logout()
      .pipe(take(1))
      .subscribe(_ => {
        this.router.navigateByUrl('/auth/login');
      })
  }
}

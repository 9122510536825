import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/shared/shared.module';
import { SmsChatComponent } from './chat/sms-chat.component';
import { SmsService } from './sms.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModalModule,
        SharedModule
    ],
    declarations: [
        SmsChatComponent
    ],
    providers: [
        SmsService
    ]
})
export class SmsModule {}

<div class="modal-header modal-colored-header bg-warning">
  <h4 class="modal-title">
    Discard unsaved changes?
  </h4>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light me-1" (click)="modal.dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-warning" (click)="modal.close()">
    Discard
  </button>
</div>

import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateTime } from 'luxon';

const OPTION_STEP_MINUTES = 15;

@Component({
  selector: 'app-timepicker',
  templateUrl: './time-picker.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimePickerComponent),
    multi: true
  }]
})
export class TimePickerComponent implements OnInit, ControlValueAccessor  {

  private _format = 'HH:mm';
  private _min: DateTime = DateTime.fromISO('00:00');
  private _max: DateTime = DateTime.fromISO('23:45');
  private _propagateChange: (value: string) => void;
  private _selectedValue: string;
  private _relativeTo: DateTime;

  get max(): string {
    return this._max.toFormat('HH:mm');
  }
  @Input()
  set max(value: string | DateTime) {
    if (value instanceof DateTime) {
      this._max = value;
    } else {
      this._max = DateTime.fromISO(value);
    }
    this.bindOptions();
  }

  get min(): string {
    return this._min.toFormat('HH:mm');
  }
  @Input()
  set min(value: string | DateTime) {
    if (value instanceof DateTime) {
      this._min = value;
    } else {
      this._min = DateTime.fromISO(value);
    }
    this.bindOptions();
  }

  options: DateTime[] = [];

  @Input()
  set relativeTo(value: string) {
    this._relativeTo = DateTime.fromISO(value);
    this.bindOptions();
  }

  get selectedValue(): string {
    return this._selectedValue;
  }
  set selectedValue(value: string) {
    this._selectedValue = value;
    this._propagateChange(this._selectedValue);
  }

  @Input()
  set use24HourTime(value: boolean) {
    this._format = value ? 'HH:mm' : 'h:mm a';
  }

  @Input() valid = true;

  private bindOptions(): void {
    this.options = [];

    if (!this._min.isValid) { return; }
    if (!this._max.isValid) { return; }

    let t = this._min;

    if (!!this._relativeTo) {
      // There's a chance the date for this is wrong, so we need to make it the same date as min
      const newRelativeTo = this._min.set({ hour: this._relativeTo.hour, minute: this._relativeTo.minute });
      this._relativeTo = newRelativeTo;

      if (this._relativeTo >= t) {
        t = this._relativeTo.plus({ minutes: OPTION_STEP_MINUTES });
      }
    }

    while (t < this._max) {
      this.options.push(t);
      t = t.plus({ minutes: OPTION_STEP_MINUTES });
    }
  }

  formatOption(o: DateTime): string {
    const s = o.toFormat(this._format);

    if (!this._relativeTo) { return s; }

    const diff = o.diff(this._relativeTo, ['hours', 'minutes']);

    if (diff.hours === 0) {
      return `${s} (${diff.minutes} mins)`;
    }

    if (diff.hours === 1 && diff.minutes === 0) {
      return `${s} (1 hr)`;
    }

    switch (diff.minutes) {
      case 0:
        return `${s} (${diff.hours} hrs)`;

      case 15:
        return `${s} (${diff.hours} ¼ hrs)`;

      case 30:
        return `${s} (${diff.hours} ½ hrs)`;

      case 45:
        return `${s} (${diff.hours} ¾ hrs)`;
    }

    return `${s} (${diff.hours + (diff.minutes / 60)} hrs)`;
  }

  ngOnInit(): void {
    this.bindOptions();

    if (!this._selectedValue && this.options.length > 0) {
      this._selectedValue = this.options[0].toFormat('HH:mm');
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this._propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {}

  writeValue(value: any): void {
    if (!value) { return; }
    this._selectedValue = value;
  }

}

import { ApplicationRef, Component, OnDestroy, OnInit } from '@angular/core';
import { SwUpdate, VersionInstallationFailedEvent, VersionReadyEvent } from '@angular/service-worker';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, concat, interval, Subject } from 'rxjs';
import { first, take, takeUntil } from 'rxjs/operators';
import { BranchesService } from 'src/app/branches/branches.service';
import { SignalRService } from 'src/app/signalr/signalr.service';
import { SmsService } from 'src/app/sms/sms.service';
import { ToastService } from 'src/shared';
import { AlertModalService } from 'src/shared/alert-modal/alert-modal.service';
import { INotification } from '../notification.model';
import { NotificationsService } from '../services/notifications.service';

@Component({
  selector: 'app-notification-menu',
  templateUrl: './notification-menu.component.html'
})
export class NotificationMenuComponent implements OnInit, OnDestroy {

  constructor(
    private _alerts: AlertModalService,
    private _branchService: BranchesService,
    private _notificationsService: NotificationsService,
    private _logger: NGXLogger,
    private _signalR: SignalRService,
    private _smsService: SmsService,
    private _swUpdates: SwUpdate,
    private _toast: ToastService
  ) { }

  private _destroyed$ = new Subject();
  private _hasNewNotifications = new BehaviorSubject<boolean>(false);
  private _notifications = new BehaviorSubject<INotification[]>([]);
  private _show$ = new BehaviorSubject<boolean>(false);

  public hasNewNotifications$ = this._hasNewNotifications.asObservable();
  public notifications$ = this._notifications.asObservable();
  public show$ = this._show$.asObservable();

  private _addNotification(notification: INotification): void {
    const notifications = this._notifications.value;
    if (notifications.some(n => n.notificationRef === notification.notificationRef)) {
      return;
    }

    this._notifications.next([...notifications, notification]);
    this._hasNewNotifications.next(true);
  }

  private _bindUpdateCheck() {
    interval(60 * 60 * 1000)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(async () => {
        try {
          await this._swUpdates.checkForUpdate();
        }
        catch (err) {
          this._logger.error('UpdateCheck', err);
        }
      });
  }

  private _bindBranch() {
    this._branchService.branch$
      .subscribe((branch) => {
        if (!!branch) {
          this._getPendingNotifications();
        }
        else {
          this._notifications.next([]);
        }

        this._show$.next(!!branch);
      });
  }

  private _bindSignalR() {
    this._signalR.notificationPosted$.subscribe((notification: INotification) => {
      if (notification.priority >= 0) {
        this._addNotification(notification);
      }
    });
  }

  private _bindSwUpdates() {
    this._swUpdates.versionUpdates
      .pipe(takeUntil(this._destroyed$))
      .subscribe(evt => {
        switch(evt.type) {
          case 'VERSION_DETECTED':
            this._logger.info(`swUpdates downloading new version ${evt.version.hash}`);
            break;

          case 'VERSION_READY':
            this._updateReady(evt);
            break;

          case 'VERSION_INSTALLATION_FAILED':
            this._updateFailed(evt);
            break;
        }
      });

    this._swUpdates.unrecoverable
      .pipe(takeUntil(this._destroyed$))
      .subscribe(evt => {
        this._logger.error('swUpdates unrecoverable error', evt);
        this._alerts.danger('An error occurred that we cannot recover from. Please refresh the page.', 'Unrecoverable Error');
      });
  }

  private _getPendingNotifications(): void {
    this._notificationsService.getPending()
      .pipe(take(1))
      .subscribe(notifications => {
        this._logger.debug('NotificationMenuComponent._getPendingNotifications', notifications);
        notifications.forEach(notification => {
          this._addNotification(notification);
        });
      });
  }

  private _updateFailed(evt: VersionInstallationFailedEvent) {
    this._logger.error(`swUpdates failed to install version ${evt.version.hash}: ${evt.error}`);

    this._alerts.danger('Please refresh the page.', 'App update failed!');
  }

  private _updateReady(evt: VersionReadyEvent) {
    this._logger.info(`swUpdates current app version ${evt.currentVersion.hash}`);
    this._logger.info(`swUpdates new version ready ${evt.latestVersion.hash}`);

    this._toast.info('App update available.');

    this._addNotification({
      createdAt: new Date(),
      message: 'App update available.',
      notificationRef: new Date().valueOf() * -1,
      priority: 5,
      objectType: 'Update',
      objectRef: 1
    });
  }

  ngOnInit() {
    this._bindUpdateCheck();
    this._bindBranch();
    this._bindSignalR();
    this._bindSwUpdates();
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  public clearAll(): void {
    this._notifications.next([]);
    this._hasNewNotifications.next(false);
  }

  public getNotificationIcon(objectType?: string): string {
    switch (objectType?.toLowerCase()) {
      case 'booking': return 'bi-calendar-event';
      case 'sms': return 'bi-chat-left-text';
      default: return 'bi-bell';
    }
  }

  public getNotificationIconBackgroundColour(priority: number): string {
    switch (priority) {
      case -2: return 'bg-secondary';
      case -1: return 'bg-info';
      case 1: return 'bg-warning';
      case 2: return 'bg-danger';
      default: return 'bg-primary';
    }
  }

  public onMenuClicked(): void {
    this._hasNewNotifications.next(false);
  }

  public onNotificationClicked(notification: INotification): void {
    if (notification.notificationRef > 0) {
      this._notificationsService.dismiss(notification.notificationRef).pipe(take(1)).subscribe(() => {
        this._logger.debug('NotificationMenuComponent.onNotificationClicked', notification);
      });
    }

    if (!notification.objectType) { return; }
    if (!notification.objectRef) { return; }

    switch (notification.objectType) {
      case 'Booking':
        break;

      case 'SMS':
        this._smsService.openChatForSms(notification.objectRef);
        break;

      case 'Update':
        document.location.reload();
        break;

      default:
        this._logger.debug('NotificationMenuComponent.onNotificationClicked', notification);
        break;
    }
  }
}


import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { BranchesService } from '../branches/branches.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent {

  public readonly branch$ = this._branchService.branch$;
  public readonly user$ = this._authService.user$;

  constructor(
    private _authService: AuthService,
    private _branchService: BranchesService
  ) { }

}

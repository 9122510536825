import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './alert-modal.component.html'
})
export class AlertModalComponent {

  @Input() alertText = '';
  @Input() alertTitle?: string;
  @Input() icon?: string;
  @Input() buttonText = 'Continue';
  @Input() modalStyle: 'danger' | 'info' | 'success' | 'warning' = 'danger';

  constructor(
    public modal: NgbActiveModal
  ) { }

}

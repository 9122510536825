<li class="notification-list" *ngIf="(branches$ | async).length > 1 || !!(isAdmin$ | async)">
  <a class="nav-link" [routerLink]='["/branches"]' routerLinkActive="active" title="Branches">
    <i class="bi bi-building noti-icon"></i>
  </a>
</li>

<li class="dropdown notification-list topbar-dropdown" *ngIf="(branches$ | async).length > 1">

  <a class="nav-link dropdown-toggle arrow-none" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    <span *ngIf="(currentBranch$ | async)" class="align-middle">{{(currentBranch$ | async).name}}</span>
    <span *ngIf="!(currentBranch$ | async)" class="align-middle">Branch</span>
    <i class="bi bi-chevron-down ms-1 align-middle"></i>
  </a>

  <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu" aria-labelledby="navbarDropdown">
    <a *ngFor="let branch of branches$ | async"
        (click)="setBranch(branch)"
        class="dropdown-item notify-item cursor-pointer"
        [ngClass]="{'active': currentBranch$ === branch}">
      <small class="d-block text-muted" *ngIf="branch.name !== branch.business.name">{{branch.business.name}}</small>
      {{branch.name}}
    </a>
  </div>
</li>

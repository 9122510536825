import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { take } from 'rxjs/operators';
import { BranchesService } from '../branches/branches.service';

@Component({
  templateUrl: './main.component.html'
})
export class MainComponent implements OnInit {

  now = new Date();

  constructor(
    private _branchService: BranchesService,
    private _logger: NGXLogger,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this._logger.trace('MainComponent | ngOnInit');

    if (this._router.url !== '/') { return; }

    this._branchService.getCurrentBranch()
      .pipe(take(1))
      .subscribe(currentBranch => {
        this._logger.trace('MainComponent|currentBranch', currentBranch);

        if (!!currentBranch) {
          this._router.navigateByUrl('/appointments');
        } else {
          this._router.navigateByUrl('/branches');
        }

        return;

        this._branchService.setBranch(currentBranch)
          .pipe(take(1))
          .subscribe(() => {
            this._router.navigateByUrl('/appointments');
          }, err => {
            this._router.navigateByUrl('/branches');
          });
      });
  }
}

<div class="text-center w-75 m-auto">
  <h4>Reset Password</h4>
  <p class="mb-4">Enter your email address and confirm your new password.</p>
</div>

<form #resetForm="ngForm" (ngSubmit)="onSubmit()">

  <app-api-error [error]="error"></app-api-error>

  <div class="form-floating mb-3">
    <input type="email" class="form-control" id="email"
        maxlength="512"
        required
        placeholder="Enter your email"
        [disabled]="isThinking"
        [(ngModel)]="email" name="email">
    <label for="email">Email</label>
  </div>

  <div class="form-floating mb-3">
    <input type="password" class="form-control" id="password"
        maxlength="512"
        required
        placeholder="Enter your new password"
        [disabled]="isThinking"
        [(ngModel)]="password" name="password">
    <label for="password">Password</label>
  </div>

  <div class="form-floating mb-3">
    <input type="password" class="form-control" id="passwordConfirmation"
        maxlength="512"
        required
        placeholder="Confirm your new password"
        [disabled]="isThinking"
        [(ngModel)]="passwordConfirmation" name="passwordConfirmation">
    <label for="passwordConfirmation">Confirm Password</label>
  </div>

  <div class="text-center mb-3">
    <button type="submit" class="btn btn-primary" [disabled]="resetForm.invalid || isThinking">Set Password</button>
  </div>

  <div class="text-center">
    <a [routerLink]='["/auth/login"]' class="text-muted"><small>Login</small></a>
  </div>

</form>
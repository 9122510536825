<div class="row">
  <div class="col-12">

    <div class="page-title-box">
      <h4 class="page-title">My eSalon</h4>
    </div>

  </div>
</div>

<div class="row">
  <div class="col-12">

    <div class="card">
      <div class="card-body">
        <h4 class="header-title">Branches</h4>

        <p class="text-muted font-14">Select a branch to manage it's appointments.</p>

        <div class="list-group">
          <a *ngFor="let branch of branches$ | async"
              (click)="setBranch(branch)"
              class="list-group-item list-group-item-action cursor-pointer"
              [ngClass]="{'active': currentBranch$ === branch}">
            <small class="d-block text-muted">{{branch.business.name}}</small>
            {{branch.name}}
          </a>
        </div>

      </div>
    </div>

  </div>
</div>

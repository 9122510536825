import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { combineLatest, Observable, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';
import { BranchesService, IBranch } from './branches.service';

@Injectable({ providedIn: 'root'})
export class BranchGuard  {

  constructor(
    private _authService: AuthService,
    private _branchesService: BranchesService,
    private _logger: NGXLogger,
    private _router: Router
  ) { }

  private handleAuthentication(
    isAuthenticated: boolean,
    currentBranch: IBranch|null,
    state: RouterStateSnapshot
  ): void {
    if (!isAuthenticated) {
      this._router.navigate(['auth', 'login'], {
        queryParams: {
          returnUrl: state.url
        }
      });
    }

    if (!currentBranch) {
      this._router.navigate(['branches'], {
        queryParams: {
          returnUrl: state.url
        }
      });
    }
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this._logger.trace('BranchGuard | canActivate');

    const isAuthenticated$ = this._authService.isAuthenticated();
    const currentBranch$ = this._branchesService.getCurrentBranch();

    return combineLatest([isAuthenticated$, currentBranch$])
      .pipe(
        tap(([isAuthenticated, currentBranch]) => this.handleAuthentication(isAuthenticated, currentBranch, state)),
        mergeMap(([isAuthenticated, currentBranch]) => of(isAuthenticated && (currentBranch !== null)))
      );
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';
import { BranchGuard } from './branches/branch.guard';

import { DashboardComponent } from './dashboard/dashboard.component';
import { MainComponent } from './main/main.component';

const routes: Routes = [
  { path: 'error', loadChildren: () => import('./errors/errors.module').then(m => m.ErrorModule) },
  {
    path: '', component: MainComponent, canActivate: [AuthGuard], children: [
      {
        path: 'appointments',
        loadChildren: () => import('./appointments/appointments.module').then(mod => mod.AppointmentsModule),
        canActivate: [BranchGuard]
      },
      { path: 'branches', loadChildren: () => import('./branches/branches.module').then(mod => mod.BranchesModule) },
      { path: 'dashboard', component: DashboardComponent, canActivate: [BranchGuard] },
      { path: 'settings', loadChildren: () => import('./settings/settings.module').then(mod => mod.SettingsModule) },
    ]
  },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(mod => mod.AuthModule) },
  { path: '**', redirectTo: 'error/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';

import { AuthModule } from './auth/auth.module';
import { AuthInterceptor } from './auth/auth.interceptor';
import { BranchesModule } from './branches/branches.module';

import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SharedModule } from '../shared/shared.module';
import { RaygunErrorHandler } from './app.raygun';
import { NotificationMenuComponent } from './notifications/notification-menu/notification-menu.component';
import { SmsModule } from './sms/sms.module';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    NavbarComponent,
    NotificationMenuComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),

    LoggerModule.forRoot({ level: (environment.production) ? NgxLoggerLevel.INFO : NgxLoggerLevel.TRACE, disableFileDetails: true }),

    AppRoutingModule,
    AuthModule,
    BranchesModule,
    SharedModule,
    SmsModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: RaygunErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

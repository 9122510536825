import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SignalRService } from 'src/app/signalr/signalr.service';
import { INotification } from '../notification.model';

@Injectable({ providedIn: 'root' })
export class NotificationsService {

  constructor(
    @Inject('BASE_URL') private _baseUrl: string,
    private _http: HttpClient,
    private _signalR: SignalRService
  ) { }

  public dismiss(notificationRef: number): Observable<void> {
    return this._http.post<void>(`${this._baseUrl}api/notifications/${notificationRef}/dismiss`, { })
      .pipe(
        tap(() => {
          this._signalR.dismissNotification(notificationRef);
        })
      );
  }

  public getPending(): Observable<INotification[]> {
    return this._http.get<INotification[]>(`${this._baseUrl}api/notifications/pending`)
      .pipe(
        catchError(err => of([])),
        map(notifications => notifications.map((n: INotification) => {
          n.createdAt = new Date(n.createdAt);
          return n;
        }))
      );
  }
}

import { Component, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { take } from "rxjs/operators";
import { ApiErrorModel } from "src/shared/api-error/api-error.model";
import { AuthService } from "../auth.service";

@Component({
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent {

  error: ApiErrorModel
  isThinking = false;

  email: string;

  @ViewChild(NgForm) resetForm: NgForm;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  onSubmit(): void {
    if (this.isThinking) { return; }
    if (this.resetForm.invalid) { return; }

    this.isThinking = true;
    this.error = null;

    this.authService.forgotPassword(this.email)
      .pipe(take(1))
      .subscribe(_ => {
        this.router.navigateByUrl('/auth/forgotpasswordconfirmation');
      }, (error: ApiErrorModel) => {
        this.error = error;
        this.isThinking = false;
      });
  }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root'})
export class AuthGuard  {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  private handleAuthentication(isAuthenticated: boolean, state: RouterStateSnapshot): void {
    if (!isAuthenticated) {
      this.router.navigate(['auth', 'login'], {
        queryParams: {
          returnUrl: state.url
        }
      });
    }
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isAuthenticated()
      .pipe(tap(isAuthenticated => this.handleAuthentication(isAuthenticated, state)));
  }

}

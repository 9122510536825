import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './discard-modal.component.html'
})
export class DiscardModalComponent {

  constructor(
    public modal: NgbActiveModal
  ) { }

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { AlertsComponent } from './alerts/alerts.component';
import { ApiErrorComponent } from './api-error/api-error.component';
import { BusyComponent } from './busy/busy.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { DiscardModalComponent } from './discard-modal/discard-modal.component';
import { LoadingComponent } from './loading/loading.component';
import { TimeAgoPipe } from './pipes/date/time-ago';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { ToastModule } from './toast/toast.module';

export { ApiErrorModel } from './api-error/api-error.model';
export { DiscardModalComponent } from './discard-modal/discard-modal.component';
export { TimePickerComponent } from './time-picker/time-picker.component';
export * from './pagination';
export * from './validators/validators';

export {
  ToastContainerComponent,
  ToastService,
  ToastModule
} from './toast/toast.module';

@NgModule({
  declarations: [
    AlertsComponent,
    AlertModalComponent,
    ApiErrorComponent,
    BusyComponent,
    ConfirmModalComponent,
    DiscardModalComponent,
    LoadingComponent,
    TimePickerComponent,

    TimeAgoPipe
  ],
  imports: [
    CommonModule,
    FormsModule,

    ToastModule
  ],
  exports: [
    ToastModule,

    AlertsComponent,
    AlertModalComponent,
    ApiErrorComponent,
    BusyComponent,
    ConfirmModalComponent,
    DiscardModalComponent,
    LoadingComponent,
    TimePickerComponent,

    TimeAgoPipe
  ]
})
export class SharedModule { }

<li class="dropdown notification-list" *ngIf="show$ | async">

  <a id="topbar-notifydrop"
      class="nav-link dropdown-toggle arrow-none"
      data-bs-toggle="dropdown"
      href="#"
      role="button"
      aria-haspopup="true"
      aria-expanded="false"
      (click)="onMenuClicked()">
    <i class="bi bi-bell noti-icon"></i>
    <span class="noti-icon-badge" *ngIf="hasNewNotifications$ | async"></span>
  </a>

  <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated dropdown-lg"
      aria-labelledby="topbar-notifydrop">

    <div class="dropdown-item noti-title">
      <h5 class="m-0">
        <span class="float-end" *ngIf="(notifications$ | async)?.length > 0">
          <a class="text-dark cursor-pointer" (click)="clearAll()">
            <small>Clear All</small>
          </a>
        </span>
        Notifications
      </h5>
    </div>

    <div data-simplebar style="max-height: 230px;">

      <div *ngIf="(notifications$ | async)?.length > 0; else none">
        <div class="dropdown-item notify-item" *ngFor="let notification of notifications$ | async" (click)="onNotificationClicked(notification)">
          <div class="notify-icon" [ngClass]="getNotificationIconBackgroundColour(notification.priority)">
            <i class="bi" [ngClass]="getNotificationIcon(notification.objectType)"></i>
          </div>
          <p class="notify-details">
            {{ notification.message }}
            <small>{{ notification.createdAt | timeAgo }}</small>
          </p>
        </div>
      </div>

    </div>
  </div>

</li>

<ng-template #none>
  <div class="dropdown-item notify-item text-muted fst-italic">All clear, enjoy the breathing room...</div>
</ng-template>
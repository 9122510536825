import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { take } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Component({
  templateUrl: './login.component.html'
})
export class LoginComponent {

  error: string;
  isThinking = false;

  email: string;
  password: string;
  remember = false;

  @ViewChild(NgForm) form: NgForm;

  constructor(
    private authService: AuthService
  ) { }

  onSubmit(): void {
    if (this.isThinking) { return; }
    if (this.form.invalid) { return; }

    this.isThinking = true;
    this.error = null;

    this.authService.login(this.email, this.password, this.remember)
      .pipe(take(1))
      .subscribe(_ => {
        this.isThinking = false;
      }, error => {
        this.error = error.error;
        this.password = null;
        this.isThinking = false;
      });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { ApiErrorModel } from 'src/shared/api-error/api-error.model';
import { ToastService } from 'src/shared/shared.module';
import { AuthService } from '../auth.service';

@Component({
  templateUrl: './confirm.component.html'
})
export class ConfirmComponent implements OnInit {

  private _code: string;
  private _userId: string;

  error: ApiErrorModel;
  isThinking = false;

  password: string;
  passwordConfirm: string;

  @ViewChild(NgForm) confirmForm: NgForm;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this._code = this.route.snapshot.queryParams.code;
    this._userId = this.route.snapshot.queryParams.userId;
  }

  onSubmit(): void {
    if (this.isThinking) { return; }
    if (this.confirmForm.invalid) { return; }

    this.isThinking = true;
    this.error = null;

    this.authService.confirmAccount(this._userId, this._code, this.password, this.passwordConfirm)
      .pipe(take(1))
      .subscribe(() => {
        this.router.navigateByUrl('/auth/login');
      }, (error: ApiErrorModel) => {
        this.error = error;
        this.isThinking = false;
      });
  }

}

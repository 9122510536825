import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AlertsService {

  public alerts: IAlert[] = [];

  public error(text: string, error?: any): void {
    this.alerts.push({ error, text, style: 'danger' });
  }

  public info(text: string): void {
    this.alerts.push({ text, style: 'info' });
  }

  public success(text: string): void {
    this.alerts.push({ text, style: 'success' });
  }

  public warning(text: string): void {
    this.alerts.push({ text, style: 'warning' });
  }
}

export interface IAlert {
  error?: any;
  style: string;
  text: string;
}

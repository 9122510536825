import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PaginatedList } from 'src/shared';
import { ICustomer } from './customer.model';
import { ICustomerSearchQuery } from './customers.module';

@Injectable({ providedIn: 'root' })
export class CustomersService {

  constructor(
    @Inject('BASE_URL') private baseUrl: string,
    private http: HttpClient
  ) { }

  delete(customerRef: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}api/customers/${customerRef}`);
  }

  emailExists(email: string, ignoreCustomerRef?: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}api/customers/exists/email/${email}?ignoreCustomerRef=${ignoreCustomerRef}`);
  }

  find(customerRef: number): Observable<ICustomer> {
    return this.http.get<ICustomer>(`${this.baseUrl}api/customers/${customerRef}`);
  }

  mobileExists(mobile: string, ignoreCustomerRef?: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}api/customers/exists/mobile/${mobile}?ignoreCustomerRef=${ignoreCustomerRef}`);
  }

  restore(customerRef: number): Observable<ICustomer> {
    return this.http.patch<ICustomer>(`${this.baseUrl}api/customers/${customerRef}/restore`, null);
  }

  save(customer: ICustomer): Observable<ICustomer> {
    return customer.customerRef > 0
      ? this.http.put<ICustomer>(`${this.baseUrl}api/customers/${customer.customerRef}`, customer)
      : this.http.post<ICustomer>(`${this.baseUrl}api/customers`, customer);
  }

  search(query: Partial<ICustomerSearchQuery>): Observable<PaginatedList<ICustomer>> {

    if (!query.excludeCustomerRef) { delete(query.excludeCustomerRef); }

    if (!query.term || query.term.length < 2) {
      return of({
        hasNextPage: false,
        hasPreviousPage: false,
        items: [],
        pageNo: 0,
        pageSize: 100,
        totalItems: 0,
        totalPages: 0
      });
    }
    return this.http.get<PaginatedList<ICustomer>>(`${this.baseUrl}api/customers/search`, { params: query });
  }

}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { BranchesService, IBranch } from '../branches.service';

@Component({
  templateUrl: './branch-list.component.html'
})
export class BranchListComponent {

  public readonly branches$ = this._branchesService.branches$;
  public readonly currentBranch$ = this._branchesService.branch$;

  constructor(
    private _branchesService: BranchesService,
    private _router: Router
  ) { }

  setBranch(branch: IBranch): void {
    this._branchesService.setBranch(branch)
      .pipe(take(1)).subscribe(_ => {});
    this._router.navigateByUrl('appointments');
  }
}

import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isValidNumber } from 'libphonenumber-js';

const PHONE_REGEXP = /^[- +()0-9]{7,}/;

function isEmptyInputValue(value: any): boolean {
  /**
   * Check if the object is a string or array before evaluating the length attribute.
   * This avoids falsely rejecting objects that contain a custom length attribute.
   * For example, the object {id: 1, length: 0, width: 0} should not be returned as empty.
   */
  return value == null ||
      ((typeof value === 'string' || Array.isArray(value)) && value.length === 0);
}

@Injectable({ providedIn: 'root' })
export class ESalonValidators {

  static phone(control: AbstractControl): ValidationErrors | null {
    return phoneValidator(control);
  }

}

export function phoneValidator(control: AbstractControl): ValidationErrors | null {
  const failed = { phone: true };

  if (isEmptyInputValue(control.value)) { return null; }
  if (!PHONE_REGEXP.test(control.value)) { return failed; }
  if (!isValidNumber(control.value, 'NZ')) { return failed; }
  return null;
}

import { Inject, Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { BranchesService, IBranch } from '../branches/branches.service';
import { INotification } from '../notifications/notification.model';

export interface ISignalRSmsReceived {
  branchRef: number;
  customerRef: number;
  incomingSmsId: number;
  smsRef: number;
}

@Injectable({ providedIn: 'root' })
export class SignalRService {

  constructor(
    @Inject('MANAGE_URL') manageUrl: string,
    private _authService: AuthService,
    private _branchesService: BranchesService,
    private _logger: NGXLogger
  ) {
    this._manageUrl = manageUrl;

    this._configureHubConnection();
    this._bindBranchesService();
  }

  private _authToken: string;
  private _branch?: IBranch;
  private _hubConnection: signalR.HubConnection;
  private _manageUrl: string;

  public bookingCancelled$ = new Subject<number>();
  public bookingChanged$ = new Subject<number>();
  public bookingConfirmed$ = new Subject<number>();
  public bookingCreated$ = new Subject<number>();
  public notificationDismissed$ = new Subject<number>();
  public notificationPosted$ = new Subject<INotification>();
  public smsReceived$ = new Subject<ISignalRSmsReceived>();

  private _bindBranchesService(): void {
    this._branchesService.branch$.subscribe(async (branch) => {
      if (!!branch) {
        await this.connectAsync(branch);
      }
      else {
        await this.disconnectAsync();
      }
    });
  }

  private _configureHubConnection(): void {
    this._hubConnection = new signalR.HubConnectionBuilder()
    .configureLogging(signalR.LogLevel.Information)
    .withAutomaticReconnect()
    .withUrl(`${this._manageUrl}hubs/business`, {
      accessTokenFactory: () => this._authToken
    })
    .build();

    this._hubConnection.onclose(async () => await this._hubClosedAsync());

    this._hubConnection.on('BookingCancelled', (headBookingRef: number) => {
      this._logger.debug(`SignalR | BookingCancelled | ${headBookingRef}`);
      this.bookingCancelled$.next(headBookingRef);
    });

    this._hubConnection.on('BookingChanged', (headBookingRef: number) => {
      this._logger.debug(`SignalR | BookingChanged | ${headBookingRef}`);
      this.bookingChanged$.next(headBookingRef);
    });

    this._hubConnection.on('BookingConfirmed', (headBookingRef: number, smsRef?: number) => {
      this._logger.debug(`SignalR | BookingConfirmed | ${headBookingRef}`);
      this.bookingConfirmed$.next(headBookingRef);
    });

    this._hubConnection.on('BookingCreated', (headBookingRef: number) => {
      this._logger.debug(`SignalR | BookingCreated | ${headBookingRef}`);
      this.bookingCreated$.next(headBookingRef);
    });

    this._hubConnection.on('NotificationDismissed', (notificationRef: number) => {
      this._logger.debug(`SignalR | NotificationDismissed | ${notificationRef}`);
      this.notificationDismissed$.next(notificationRef);
    });

    this._hubConnection.on(
      'NotificationPosted', (
        notificationRef: number,
        message: string,
        priority: number,
        objectType?: string,
        objectRef?: number
      ) =>
    {
      this._logger.debug(`SignalR | NotificationPosted | ${notificationRef}`);
      this.notificationPosted$.next({
        notificationRef,
        createdAt: new Date(),
        message,
        priority,
        objectType,
        objectRef
      });
    });

    this._hubConnection.on('SmsReceived', (incomingSmsId: number, smsRef: number, branchRef: number, customerRef: number) => {
      this._logger.debug(`SignalR | SmsReceived | ${smsRef}`);
      this.smsReceived$.next({
        branchRef,
        customerRef,
        incomingSmsId,
        smsRef
      });
    });

    this._hubConnection.on('SmsSent', (smsRef: number) => {
      this._logger.debug(`SignalR | SmsSent | ${smsRef}`);
    });

    this._hubConnection.on('Test', () => {
      this._logger.debug('SignalR | Test');
    });
  }

  private async _hubClosedAsync(): Promise<void> {
    if (!!this._branch) {
      await this.connectAsync(this._branch);
    }
  }

  public async connectAsync(branch: IBranch): Promise<void> {
    this._logger.info('SignalR | connectAsync');

    if (!!this._branch) {
      if (this._branch.subscriptionId === branch.subscriptionId) { return; }
      if (this._branch.id === branch.id) { return; }

      await this.disconnectAsync();
    }

    this._branch = branch;

    if (!!branch) {
      this._authToken = this._authService.getAuthToken();
      await this._hubConnection.start();
    }
  }

  public async disconnectAsync(): Promise<void> {
    this._logger.debug('SignalR | disconnectAsync');
    this._branch = undefined;

    if (this._hubConnection.state === signalR.HubConnectionState.Connected) {
      await this._hubConnection.stop();
    }
  }

  public dismissNotification(notificationRef: number): void {
    this._hubConnection.send('DismissNotification', notificationRef);
  }

}

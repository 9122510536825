import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BranchListComponent } from './branch-list/branch-list.component';
import { BranchListResolver } from './resolvers/branch-list.resolver';

const routes: Routes = [
  { path: '', component: BranchListComponent, resolve: { branches: BranchListResolver } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BranchesRoutingModule { }

<div class="text-center w-75 m-auto">
  <h4>Login</h4>
  <p class="mb-4">Enter your email address and password to log into eSalon.</p>
</div>

<div class="alert alert-danger text-center" *ngIf="error == 'unauthorized'">
  <h5 class="my-0">Incorrect username or password.</h5>
</div>

<div class="alert alert-danger text-center" *ngIf="error == 'locked'">
  <h5>Your account has been locked.</h5>
  <p class="mb-0">Please contact eSalon for further information.</p>
</div>

<form #loginForm="ngForm" (ngSubmit)="onSubmit()">

  <div class="form-floating mb-3">
    <input type="email" class="form-control" id="email"
        autocomplete="username"
        maxlength="512"
        placeholder="Enter your email"
        required
        [disabled]="isThinking"
        [(ngModel)]="email" name="email">
    <label for="email">Email address</label>
  </div>

  <div class="form-floating mb-3">
    <input type="password" class="form-control" id="password"
        autocomplete="current-password"
        maxlength="512"
        required
        placeholder="Enter your password"
        [disabled]="isThinking"
        [(ngModel)]="password" name="password">
    <label for="password">Password</label>
  </div>

  <div class="form-check mb-3">
    <input type="checkbox" class="form-check-input"
        id="remember"
        name="remember"
        [disabled]="isThinking"
        [(ngModel)]="remember">
    <label class="form-check-label" for="remember">
      Remember me
    </label>
  </div>

  <div class="text-center mb-3">
    <button type="submit" class="btn btn-primary" [disabled]="loginForm.invalid || isThinking">Login</button>
  </div>

  <div class="text-center">
  <a [routerLink]='["/auth/forgotpassword"]' class="text-muted"><small>Forgot your password?</small></a>
  </div>

</form>

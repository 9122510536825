import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { BranchesRoutingModule } from './branches-routing.module';

import { BranchListComponent } from './branch-list/branch-list.component';
import { BranchMenuComponent } from './branch-menu/branch-menu.component';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,

    BranchesRoutingModule
  ],
  declarations: [
    BranchListComponent,
    BranchMenuComponent
  ],
  exports: [
    BranchMenuComponent
  ]
})
export class BranchesModule { }

<div class="container">

  <div class="row justify-content-center">

    <div class="col col-lg-5 col-xxl-4">

      <div class="card mt-5">

        <div class="card-header p-4 text-center bg-primary">
          <img src="/images/esalon-icon-border.svg" alt="eSalon" height="30" class="me-1" />
          <img src="/images/esalon-text-white.svg" alt="eSalon" height="30" />
        </div>

        <div class="card-body p-4">
          <router-outlet></router-outlet>
        </div>

      </div>

    </div>

  </div>

</div>

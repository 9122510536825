import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AuthService, ROLE_ADMIN } from 'src/app/auth/auth.service';

import { BranchesService, IBranch } from '../branches.service';

@Component({
  selector: 'app-branch-menu',
  templateUrl: './branch-menu.component.html'
})
export class BranchMenuComponent implements OnInit, OnDestroy {

  private readonly _destroy$ = new Subject<void>();
  private readonly _isAdmin$ = new BehaviorSubject<boolean>(false);

  public readonly branches$ = this._branchesService.branches$;
  public readonly currentBranch$ = this._branchesService.branch$;
  public readonly isAdmin$ = this._isAdmin$.asObservable();

  constructor(
    private _authService: AuthService,
    private _branchesService: BranchesService
  ) { }

  private _bindBranches() {
    this.branches$
      .pipe(take(1))
      .subscribe(branches => {
        if (branches.length < 1) {
          this._branchesService.getBranches().subscribe();
        }
      });
  }

  private _bindUser() {
    this._authService.user$
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        const isAdmin = this._authService.hasRole(ROLE_ADMIN);
        this._isAdmin$.next(isAdmin);
      });
  }

  ngOnInit() {
    this._bindBranches();
    this._bindUser();
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  setBranch(branch: IBranch): void {
    this._branchesService.setBranch(branch)
      .pipe(take(1)).subscribe(_ => {});
  }
}

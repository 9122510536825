import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { ApiErrorModel, ToastService } from 'src/shared/shared.module';
import { AuthService } from '../auth.service';

@Component({
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {

  private token: string;

  error: ApiErrorModel;
  isThinking = false;

  email: string;
  password: string;
  passwordConfirmation: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams.token;
  }

  onSubmit(): void {
    if (this.isThinking) { return; }

    this.isThinking = true;
    this.error = null;

    this.authService.resetPassword(this.email, this.password, this.passwordConfirmation, this.token)
      .pipe(take(1))
      .subscribe(_ => {
        this.toastService.success('Your password has been reset.');
        this.router.navigateByUrl('/auth/resetpasswordconfirmation');
      }, error => {
        this.password = null;
        this.passwordConfirmation = null;
        this.error = error;
        this.isThinking = false;
      });
  }

}

import { Component } from '@angular/core';
import { AlertsService, IAlert } from './alerts.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html'
})
export class AlertsComponent {

  constructor(
    public alertService: AlertsService
  ) { }

  onAlertDismiss(alert: IAlert): void {
    this.alertService.alerts = this.alertService.alerts.filter(a => a !== alert);
  }

}

import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from './alert-modal.component';

@Injectable({ providedIn: 'root' })
export class AlertModalService {

  constructor(
    private _modalService: NgbModal
  ) { }

  private _open(style: string, text: string, title?: string, icon?: string): NgbModalRef {
    const modalRef = this._modalService.open(AlertModalComponent, {
      backdrop: 'static',
      centered: true,
      size: 'sm'
    });

    modalRef.componentInstance.alertTitle = title;
    modalRef.componentInstance.alertText = text;
    modalRef.componentInstance.icon = icon;
    modalRef.componentInstance.modalStyle = style;

    return modalRef;
  }

  public info(text: string, title?: string): NgbModalRef {
    return this._open('info', text, title ?? 'Oh snap!', 'x-circle');
  }

  public danger(text: string, title?: string): NgbModalRef {
    return this._open('danger', text, title ?? 'Oh snap!', 'x-circle');
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';

import { ConfirmComponent } from './confirm/confirm.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordConfirmationComponent } from './forgot-password-confirmation/forgot-password-confirmation.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordConfirmationComponent } from './reset-password-confirmation/reset-password-confirmation.component';

const routes: Routes = [
  { path: '', component: AuthComponent, children: [
    { path: 'confirm', component: ConfirmComponent },
    { path: 'login', component: LoginComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'forgotpassword', component: ForgotPasswordComponent },
    { path: 'forgotpasswordconfirmation', component: ForgotPasswordConfirmationComponent },
    { path: 'resetpassword', component: ResetPasswordComponent },
    { path: 'resetpasswordconfirmation', component: ResetPasswordConfirmationComponent },
  ]}
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AuthRoutingModule { }

<div class="content">
  <app-navbar></app-navbar>

  <div class="container-fluid">
    <app-alerts></app-alerts>

    <router-outlet></router-outlet>
  </div>
</div>

<footer class="footer">
  <div class="container-fluid">
    <div class="row">

      <div class="col-md-6">
        {{now.getFullYear()}} &copy; Hone Limited
      </div>

      <div class="col-md-6">
        <div class="text-md-end footer-links d-none d-md-block">
          <a href="https://esalon.nz/" target="_blank">About</a>
          <a href="https://esalon.nz/contact/" target="_blank">Contact Us</a>
        </div>
      </div>

    </div>
  </div>
</footer>

<app-toast-container></app-toast-container>
